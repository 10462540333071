import React from 'react';
import {
  Grid,
} from '@mui/material';
import accountStyles from '../styles';
import RegisterForm from '../../../components/RegisterForm';
import analytics from '../../../utils/analytics';

export default function RegisterFormView() {
  const classes = accountStyles();
  const [givenFirstName, setFirstName] = React.useState('');
  const [givenIdNumber, setIdNumber] = React.useState('');
  const [givenLastName, setLastName] = React.useState('');

  React.useEffect(
    () => {
      const PLRData = localStorage.getItem('PLR');
      if (PLRData && window.location.hash.includes('UR')) {
        const { firstName, lastName, idNumber } = JSON.parse(PLRData);
        setFirstName(firstName || '');
        setLastName(lastName || '');
        setIdNumber(idNumber || '');
      }
      analytics.page('PU - LOGIN', 'CREAR CUENTA');
    },
    [],
  );

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <RegisterForm
        givenFirstName={givenFirstName}
        givenLastName={givenLastName}
        givenIdNumber={givenIdNumber}
      />
    </Grid>
  );
}
