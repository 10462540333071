import { RouteComponentProps } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import JWTDecode from '../../../../../../../utils/jwtDecode';
import { patchFillForm, postPreApprove } from '../../../../../../../queries/preloan/v3';
import { getInterestRate, postBNPL, postPreLoanRequest } from '../../../../../../../queries/preloan';
import {
  FormSchemaV3, PLRStepV3, PatchFillFormResponse, PostPreLoanRequestResponseV3,
} from '../../../../../../../types/preloan/v3';
import { nonAutomaticallyRejectedOrigins, preLoanRequestStep } from '../../../formLogic';
import { addPreApproveId, identifyHandler } from '../../../../../../../utils/analytics';
import { PLRAlertState } from '../../../../../../../types/preloan';
import { PreLoanRequestTokenType } from '../../../../../../../components/LeadFormIndex/types';

// eslint-disable-next-line import/prefer-default-export
export const handleDataOK = async (params: {token: string,
    newData: {},
    handleCurrentData: (values: FormSchemaV3)=> void,
    formSchema: FormSchemaV3,
    handleSetPLRState: (state:PLRStepV3|'error'|false)=>void,
}) => {
  const {
    token, newData, handleCurrentData,
    formSchema, handleSetPLRState,
  } = params;
  const { data: preApproveData } = await postPreApprove(token);
  if (preApproveData.preApprove?.id) {
    addPreApproveId(preApproveData.preApprove.id);
  }
  switch (preApproveData.plr.step) {
    case 'LOAN_SIMULATION': {
      const interestRate = await getInterestRate(token);
      handleCurrentData({
        ...formSchema,
        ...newData,
        step: preApproveData.plr.step,
        loanParams: interestRate.data,
        origin: preApproveData.plr.origin,
      });
      return handleSetPLRState(preApproveData.plr.step);
    }
    case 'READY_FOR_LOAN': {
      handleCurrentData({
        ...formSchema,
        step: preApproveData.plr.step,
        ...(preApproveData.preApprove && {
          preApprove: {
            accepted: preApproveData.preApprove.accepted,
            id: preApproveData.preApprove.id,
            maxAmount: preApproveData.preApprove.maxAmount,
            maxInstallmentAmount: preApproveData.preApprove.maxInstallmentAmount,
            maxPeriods: preApproveData.preApprove.maxPeriods,
            productType: preApproveData.preApprove.productType,
            rate: preApproveData.preApprove.rate,
            currency: preApproveData.preApprove.currency,
          },
        }),
      });
      return handleSetPLRState(preApproveData.plr.step);
    }
    case 'ARCHIVED_FAIL':
      handleCurrentData({
        ...formSchema,
        ...newData,
        step: preApproveData.plr.step,
        firstName: preApproveData.plr.firstName,
        lastName: preApproveData.plr.lastName,
        rut: preApproveData.plr.idNumber,
        results: preApproveData.results,
        origin: preApproveData.plr.origin,
      });
      return handleSetPLRState(preApproveData.plr.step);
    default:
      return handleSetPLRState('error');
  }
};

export const returnDataForm = (params:{
  rawDataForm: PatchFillFormResponse['data']['form'],
  phone: string|undefined,
  email: string|undefined,
  formSchema: FormSchemaV3,
}) => {
  const {
    rawDataForm, phone, email, formSchema,
  } = params;
  const dataForm = {} as FormSchemaV3['dataForm'];
  const contactForm = {} as FormSchemaV3['contactForm'];
  Object.keys(rawDataForm).forEach((key) => {
    let value: string;
    if (key === 'phone') {
      value = phone ? `+56 ${phone}` : '+56 ';
      contactForm[key] = { value, type: rawDataForm[key], ok: true };
    } else if (key === 'email') {
      value = email || '';
      contactForm[key] = { value, type: rawDataForm[key], ok: true };
    } else {
      value = formSchema.dataForm[key].value;
      dataForm[key] = { value, type: rawDataForm[key] };
    }
  });
  return { dataForm, contactForm };
};

export const handlePLRstateSwitch = (params:{
    plrState: PLRStepV3|false|'error',
    formSchema: FormSchemaV3,
    handleStep: (step: number) => void,
    handleSetDataLoading: (state: boolean) => void,
    handleEnableLoading: (state: boolean) => void,
}) => {
  const {
    plrState, formSchema,
    handleStep,
    handleSetDataLoading, handleEnableLoading,
  } = params;
  switch (plrState) {
    case 'ARCHIVED_FAIL': {
      return handleStep(nonAutomaticallyRejectedOrigins
        .includes(formSchema.origin) ? preLoanRequestStep.READY_FOR_LOAN
        : preLoanRequestStep.ARCHIVED_FAIL);
    }
    case 'NEEDS_CU': {
      handleEnableLoading(false);
      return handleSetDataLoading(false);
    }
    case 'UPLOAD_DOCUMENTS':
      return handleStep(preLoanRequestStep.UPLOAD_DOCUMENTS);
    case 'FILL_FORM':
      return handleStep(preLoanRequestStep.FILL_FORM);
    case 'COMPANY_CONTACT_INFO':
      return handleStep(preLoanRequestStep.COMPANY_CONTACT_INFO);
    case 'LOAN_SIMULATION':
      return handleStep(preLoanRequestStep.LOAN_SIMULATION);
    case 'NEEDS_ICP':
      return handleStep(preLoanRequestStep.NEEDS_ICP);
    case 'READY_FOR_LOAN':
      return handleStep(preLoanRequestStep.READY_FOR_LOAN);
    case 'WAITING_COMPANY_AFFILIATION':
      return handleStep(preLoanRequestStep.WAITING_COMPANY_AFFILIATION);
    case 'COMPANY_APROVE':
      return handleStep(preLoanRequestStep.COMPANY_APPROVE);
    case 'ARCHIVED_SUCCESS':
      return handleStep(preLoanRequestStep.ARCHIVED_SUCCESS);
    case 'MANUAL_VERIFICATION':
      return handleStep(preLoanRequestStep.MANUAL_VERIFICATION);
    default:
      return handleSetDataLoading(true);
  }
};

export const getOrCreatePLR = async (params:{
  origin: string,
  rut: string,
  captchaToken: string,
  personalInfo: FormSchemaV3['personalInfo'] | null,
  userInput: FormSchemaV3['userInput']| null,
  referralCode: string|null,
  handleSetPLRState:(state:PLRStepV3|'error'|false)=>void,
  handleCurrentData: (values: FormSchemaV3)=> void,
  handleSetDataLoading: (state:boolean)=> void,
  handleSetSignedLREmail: (email:string) => void,
  handleSetAlertState: (state:PLRAlertState) => void
  formSchema: FormSchemaV3,
  searchParams: URLSearchParams,
  history: RouteComponentProps['history'],
  shouldCallBNPL: boolean,
  invisibleCaptchaToken: string,
}) => {
  const {
    origin,
    rut,
    captchaToken,
    personalInfo = null,
    userInput = null,
    referralCode = null,
    handleSetPLRState,
    handleCurrentData,
    handleSetDataLoading,
    handleSetSignedLREmail,
    handleSetAlertState,
    formSchema,
    searchParams,
    history,
    shouldCallBNPL,
    invisibleCaptchaToken,
  } = params;
  let newPLRSentObject;
  const initialSentObject = {
    origin,
    rut,
    captchaToken,
    personalInfo: personalInfo && JSON.stringify(personalInfo),
    userInput: userInput && JSON.stringify({ ...userInput }),
  };
  if (referralCode) {
    newPLRSentObject = { ...initialSentObject, referralCode };
  } else {
    newPLRSentObject = initialSentObject;
  }

  try {
    if (!formSchema.id && shouldCallBNPL) {
      const randomInteger = (min: number, max: number) => Math.floor(
        Math.random() * (max - min + 1),
      ) + min;
      await postBNPL({
        idNumber: rut,
        invisibleCaptchaToken,
        loanAmount: randomInteger(50_000, 200_000),
      });
    }
  } catch (error) {
    Sentry.setContext('BNPL endpoint error', {
      error,
    });
  }
  try {
    const res = await postPreLoanRequest<
    PostPreLoanRequestResponseV3
    >(formSchema.id, newPLRSentObject);
    const payload: PreLoanRequestTokenType = JWTDecode(res.data.token);
    identifyHandler(payload.preLoanRequestId, res.data.plr.identificationId);

    if (res.status === 206 && res.data.plr.email) {
      handleSetSignedLREmail(res.data.plr.email);
      handleSetDataLoading(false);
      return handleSetAlertState('userHasUnsignedLoanRequest');
    }
    searchParams.set('origin', res.data.plr.origin);
    searchParams.set('partner', res.data.plr.partner);
    history.push({
      search: searchParams.toString(),
    });
    const newData = {
      isRedirect: res.data.plr.step !== 'NEEDS_CU' ? 'REDIRECCION RUT' : false as FormSchemaV3['isRedirect'],
      step: res.data.plr.step,
      PLRtoken: res.data.token,
      firstName: res.data.plr.firstName,
      lastName: res.data.plr.lastName,
      needsPensionStatement: res.data.plr.needsPensionStatement,
      needsSettlement: res.data.plr.needsSettlement,
      rut: res.data.plr.idNumber,
      origin: res.data.plr.origin,
      identificationId: res.data.plr.identificationId,
      ...(res.data.preApprove && {
        preApprove: {
          accepted: res.data.preApprove?.accepted,
          id: res.data.preApprove?.id,
          maxAmount: res.data.preApprove?.maxAmount,
          productType: res.data.preApprove?.productType,
          rate: res.data.preApprove?.rate,
          maxPeriods: res.data.preApprove?.maxPeriods,
          maxInstallmentAmount: res.data.preApprove?.maxInstallmentAmount,
          currency: res.data.preApprove?.currency,
        },
      }),
      partner: res.data.plr.partner,
      partnerProducts: res.data.plr.partnerProducts,
      settlementsRequired: res.data.settlementsRequired,
      pensionStatementsRequired: res.data.pensionStatementsRequired,
    };
    if (res.data.plr.step === 'ARCHIVED_FAIL') {
      handleCurrentData({
        ...formSchema,
        ...newData,
        adminComment: res.data.plr.adminComment,
        results: res.data.results,
        debts: res.data.plr.debts,
      });
      return handleSetPLRState('ARCHIVED_FAIL');
    }
    handleCurrentData({
      ...formSchema,
      ...newData,
    });
    if (['NEEDS_CU', 'UPLOAD_DOCUMENTS', 'FILL_FORM'].includes(res.data.plr.step)) {
      if (res.data.plr.step === 'FILL_FORM') {
        const response = await patchFillForm(res.data.token);
        if (response.data.plr.step === 'DATA_OK') {
          handleSetAlertState(false);
          return handleDataOK({
            token: res.data.token,
            newData,
            handleCurrentData,
            formSchema,
            handleSetPLRState,
          });
        }
        const { dataForm, contactForm } = returnDataForm({
          rawDataForm: response.data.form,
          phone: response.data.plr.phone,
          email: response.data.plr.email,
          formSchema,
        });
        handleCurrentData({
          ...formSchema, ...newData, contactForm, dataForm, step: response.data.plr.step,
        });
      }
      handleSetAlertState(false);
      return handleSetPLRState(res.data.plr.step);
    }
    if (res.data.plr.step === 'DATA_OK') {
      handleSetAlertState(false);
      return handleDataOK({
        token: res.data.token,
        newData,
        handleCurrentData,
        formSchema,
        handleSetPLRState,
      });
    }
    if (res.data.plr.step === 'LOAN_SIMULATION') {
      try {
        const interestRate = await getInterestRate(res.data.token);
        handleCurrentData({
          ...formSchema,
          ...newData,
          step: res.data.plr.step,
          loanParams: interestRate.data,
        });
        handleSetAlertState(false);
        return handleSetPLRState(res.data.plr.step);
      } catch (e) {
        return handleSetPLRState('error');
      }
    }
    if (['COMPANY_CONTACT_INFO',
      'WAITING_COMPANY_AFFILIATION',
      'READY_FOR_LOAN',
      'COMPANY_APROVE',
      'ARCHIVED_SUCCESS',
      'NEEDS_ICP',
      'MANUAL_VERIFICATION',
    ].includes(res.data.plr.step)) {
      handleCurrentData({
        ...formSchema,
        ...newData,
        step: res.data.plr.step,
      });
      handleSetAlertState(false);
      return handleSetPLRState(res.data.plr.step);
    }
    handleSetAlertState(false);
    return res;
  } catch (e) {
    if (e === 404) {
      handleSetDataLoading(false);
      return handleSetAlertState('userHasMissingPersonalInfo');
    }
    if (e === 412) {
      handleSetDataLoading(false);
      return handleSetAlertState('userHasActiveLoan');
    }
    if (e === 413) {
      handleSetDataLoading(false);
      return handleSetAlertState('userHasSignedLoanRequest');
    }
    return handleSetPLRState('error');
  }
};
