import React from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { ExperianChallengeProps } from './types';
import { experianErrorSwitch } from './helpers';
import analytics from '../../../../../../../../utils/analytics';

export default function ExperianChallengeResults(props: ExperianChallengeProps) {
  const {
    values, handleSetDisableContinue, loading, error, handleSetStep, setFieldValue,
  } = props;
  const [pageCalled, setPageCalled] = React.useState(false);
  React.useEffect(() => {
    if (error && values.experianChallengeResult.errors
      && values.experianChallengeResult.errors.length > 0) {
      if (!pageCalled) {
        analytics.page('PLR - SITUACION LABORAL', 'ERROR CHALLENGE EXPERIAN', {
          errorType: values.experianChallengeResult.errors[0].errorCode,
          attempt: values.experianRetries,
        });
        setPageCalled(true);
      }
      handleSetDisableContinue(true);
    }
  }, [error, values.experianChallengeResult.errors]);

  const handleReset = () => {
    analytics.track('Click Boton', {
      initiator: 'usuario',
      text: 'Reintentar',
    });
    setFieldValue('experianRetries', values.experianRetries + 1);
    setFieldValue('experianChallenge', {
      data: {
        RESUMEN: {
          RUT: '',
          USUARIO: '',
          IDCHALLENGE: '',
        },
        PREGUNTAS: [],
        codigoRetorno: '',
      },
      success: false,
      errors: [],
    });
    setFieldValue('experianChallengeResponses', {});
    handleSetStep(0);
  };

  return (
    <div>
      <Typography
        gutterBottom
        align="center"
        component="div"
        minWidth={300}
      >
        Resultados verificación
      </Typography>
      {loading
      && (
      <Typography
        align="center"
        fontWeight="bold"
        component="div"
        marginTop={10}
      >
        <CircularProgress size={40} />
        <div style={{ margin: 30 }}>
          Cargando...
        </div>
      </Typography>
      )}
      {error
            && (
            <div style={{
              margin: 10,
              padding: 20,
              borderRadius: 20,
              backgroundColor: 'whitesmoke',
              textAlign: 'center',
            }}
            >
              <Typography
                align="center"
                fontWeight="bold"
                component="div"
                gutterBottom
              >
                ¡Ha ocurrido un error!
              </Typography>
              {values.experianChallengeResult.errors
              && values.experianChallengeResult.errors.map((err:{
                errorCode: string,
                 message:string}) => (
                   <Typography gutterBottom key={err.errorCode}>
                     {experianErrorSwitch(err.errorCode)}
                   </Typography>
              ))}
              <Typography
                variant="body2"
                gutterBottom
              >
                ID:
                {' '}
                <b>
                  {values.identificationId}
                </b>
              </Typography>
            </div>
            )}

      {error
            && (
              values.experianChallengeResult.errors
                    && values.experianChallengeResult.errors.find((err:{
                        errorCode: string,
                         message:string}) => ['10002', '10007', '10307', '10012'].includes(err.errorCode))
              && (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleReset}
                  >
                    Reintentar

                  </Button>
                </div>
              )
            )}
      {values.experianChallengeResult.success
            && (
            <div style={{
              margin: 10,
              padding: 20,
              borderRadius: 20,
              backgroundColor: 'whitesmoke',
            }}
            >
              <Typography
                align="center"
                fontWeight="bold"
                component="div"
                gutterBottom
              >
                ¡Verificación completa!
              </Typography>
              <Typography
                align="center"
                component="div"
                gutterBottom
              >
                Espera un momento...

              </Typography>
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={20} />
              </div>
            </div>
            )}
    </div>
  );
}
