import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_API_KEY || '' });

let lastPage: { pageCategory: string, pageName: string } | null = null;

analytics.addSourceMiddleware(async ({ payload, next }) => {
  try {
    const updatedPayload = payload as any;
    const payloadType = payload.type();
    if (payloadType === 'page') {
      const { properties } = payload.obj;
      lastPage = { pageCategory: properties?.category, pageName: properties?.name };
    } else if (payloadType === 'track') {
      if (lastPage) {
        updatedPayload.obj.properties = {
          ...(payload.obj.properties || {}),
          ...lastPage,
        };
      }
    }
    const user = await analytics.user();
    const traits = user.traits();

    if (traits && Object.keys(traits).length > 0) {
      updatedPayload.obj.properties = {
        ...(updatedPayload.obj.properties || {}),
        ...traits,
      };
      return next(updatedPayload);
    }

    return next(updatedPayload);
  } catch (error) {
    return next(payload);
  }
});

export const addPreApproveId = async (id: string) => {
  const user = await analytics.user();
  const traits = user.traits();
  if (traits?.preApproveId !== id) {
    analytics.identify(undefined, { preApproveId: id });
  }
};

export const identifyHandler = async (plrId: string, identificationId: string) => {
  const user = await analytics.user();
  const userId = user.id();
  if (!userId || userId?.toString() !== identificationId) {
    return analytics.identify(identificationId, { plrId });
  }
  const prevPlrId = user.traits()?.plrId;
  if (prevPlrId !== plrId) {
    return analytics.identify(undefined, { plrId });
  }
  return undefined;
};

export const resetAnalytics = async () => {
  const user = await analytics.user();
  if (!user.id()) {
    return;
  }
  const traits = user.traits();
  if (traits && Object.keys(traits).length > 0) {
    const newEmptyTraits: { [key: string]: any } = {};
    Object.keys(traits).forEach((key) => {
      newEmptyTraits[key] = undefined;
    });
    await analytics.identify(undefined, newEmptyTraits);
  }
};

export default analytics;
