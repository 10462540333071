import fetcher from '../../utils/fetcher';
import {
  PostCreateLoanRequestFromPLRResponse,
  GetInterestRateResponse,
} from '../../types/preloan';
import { FormSchemaV3, PostPreLoanRequestResponseV3 } from '../../types/preloan/v3';

export async function postPreLoanRequest<T extends PostPreLoanRequestResponseV3>(
  plrId: string|undefined,
  plrSentObject:{
    origin: string,
    rut:string,
    captchaToken:string,
    personalInfo: string|null,
    userInput: string|null,
    referralCode?: string
  },
): Promise<T> {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/v2`,
    'POST',
    plrId ? { preLoanRequestId: plrId } : plrSentObject,
    null,
  );
  return res;
}
export async function postCreateLoanRequestFromPLR<PreLoanRequest
>(
  token:string,
): Promise<PostCreateLoanRequestFromPLRResponse<PreLoanRequest>> {
  const loanRequest = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/loan-request`,
    'POST',
    null,
    token,
  );
  return loanRequest;
}
export async function getInterestRate(token: string): Promise<GetInterestRateResponse> {
  const res = await fetcher(`${process.env.REACT_APP_API_URL}/pre-loan-request/v2/interest-rate`, 'GET', null, token) as GetInterestRateResponse;
  res.data.riskEngineMaxAmount = res.data.currency.abbreviation === 'CLP' ? Math.round(res.data.riskEngineMaxAmount / 1000) * 1000 : res.data.riskEngineMaxAmount;
  return res;
}

export async function postCompanyInfo<PostCompanyInfoResponse>(companyInfo:{
  companyName: string,
  companyEmail: string,
  companyPhoneCode:string,
  companyPhoneNumber:string,
  companyContactName: string,
}, token: string): Promise<PostCompanyInfoResponse> {
  const res = await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/v2/company-info`,
    'POST',
    {
      companyName: companyInfo.companyName,
      companyEmail: companyInfo.companyEmail,
      companyPhoneCode: companyInfo.companyPhoneCode,
      companyPhoneNumber: companyInfo.companyPhoneNumber,
      companyContactName: companyInfo.companyContactName,
    },
    token,
  );
  return res;
}
export async function postSimulation<FormSchema extends FormSchemaV3>(
  requestLoanData: FormSchema['requestLoanData'],
  preApproveId: string,
  insuranceIds: string[],
  token: string,
) {
  await fetcher(
    `${process.env.REACT_APP_API_URL}/pre-loan-request/simulation`,
    'POST',
    {
      amount: requestLoanData.amount,
      interest: requestLoanData.interestRate,
      periods: requestLoanData.periods,
      installment: requestLoanData.installment,
      originCost: requestLoanData.originCost,
      preApproveId,
      insuranceIds: JSON.stringify(insuranceIds),
    },
    token,
  );
}

export async function postBNPL(props:{
  idNumber: string,
  invisibleCaptchaToken: string,
  loanAmount: number,
}) {
  const {
    idNumber,
    invisibleCaptchaToken,
    loanAmount,
  } = props;
  await fetcher(
    `${process.env.REACT_APP_API_URL}/partner/pre-loan-request/bnpl-dummy`,
    'POST',
    {
      rut: idNumber,
      invisibleCaptchaToken,
      amount: loanAmount,
    },
    null,
  );
}
