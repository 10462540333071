/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Sentry from '@sentry/react';
import type { FormikProps } from 'formik';
import { Typography } from '@mui/material';
import type { FormSchemaV3 } from '../../../../types/preloan/v3';
import RejectedForm from './steps/00_rejected';
import RutForm from './steps/01_rut';
import DataFetchingForm from './steps/02_dataFetching';
import ClaveUnicaForm from './steps/03_0_ClaveUnica';
import UploadDocumentsForm from './steps/03_1_UploadDocuments';
import FillForm from './steps/03_2_FillForm';
import NeedsICPForm from './steps/03_3_NeedsICP';
import LoanSimulationForm from './steps/04_LoanSimulation';
import CompanyInfoForm from './steps/05_companyInfo';
import LoanConfirmationForm from './steps/06_loanConfirmation';
import LastStepForm from './steps/07_lastStep';
import { patchPLRStep } from '../../../../queries/preloan/v2';
import { PartnerConfiguration } from '../../../../components/LeadFormIndex/types';

export const formSchema: FormSchemaV3 = {
  isRedirect: false,
  product: 'PLR V3',
  origin: '',
  PLRtoken: '',
  id: '',
  step: '',
  rut: '',
  captchaToken: '',
  firstName: '',
  lastName: '',
  email: '',
  rejectReason: '',
  referralCode: null,
  results: undefined,
  companyName: '',
  companyContactName: '',
  companyContactPhone: '+56 ',
  companyContactMail: '',
  requestedAmount: 0,
  requestedPeriods: 0,
  requestedInstallment: 0,
  loanParams: {
    canGetLoan: true,
    alpha: -0.11764706,
    beta: 0.25235294,
    payProb: {
      3: 0.72,
      6: 0.70,
      9: 0.68,
      12: 0.65,
      15: 0.60,
      18: 0.55,
      24: 0.50,
      36: 0.45,
    },
    originCost: 10000,
    riskEngineMaxAmount: 1500000,
    maxInstallmentAmount: 500000,
    riskEngineMaxPeriod: 36,
    basePaymentMethod: 'pac',
    preApproveId: '',
    productType: 'consumer',
    amountStepSize: 25000,
    insurances: [],
    minAmount: 25000,
    onlyStepSize: false,
    paymentDate: 1,
    currency: { abbreviation: 'CLP', precision: 0 },
  },
  requestLoanData: {
    amount: 0,
    interestRate: 0,
    periods: 0,
    installment: 0,
    totalCost: 0,
    monthlyCAE: 0,
    CAE: 0,
    originCost: 0,
    taxes: 0,
    insuranceCost: 0,
    checkedInsurances: [],
  },
  requestLoanDataInitialState: {
    amount: 0,
    interestRate: 0,
    periods: 0,
    installment: 0,
    totalCost: 0,
    monthlyCAE: 0,
    CAE: 0,
    originCost: 0,
    taxes: 0,
    insuranceCost: 0,
  },
  adminComment: '',
  needsSettlement: true,
  needsPensionStatement: false,
  liqFile: undefined,
  cotizFile: undefined,
  personalInfo: {
    firstName: '',
    lastName: '',
    birthDate: null,
    sex: '',
  },
  userInput: {
    workStatus: '',
    educationLevel: '',
    gigworkerMotivation: '',
    houseIncome: '',
    economicDependents: '',
    civilStatus: '',
    loanPurpose: '',
  },
  debts: undefined,
  dataForm: {
    age: { value: '', type: 'number' },
    sex: { value: '', type: 'string' },
    brute_salary: { value: '', type: 'number' },
    net_salary: { value: '', type: 'number' },
    emp_length: { value: '', type: 'number' },
    type_of_contract: { value: '', type: 'string' },
    licenses: { value: '', type: 'number' },
  },
  contactForm: {
    email: { value: '', type: 'Email', ok: true },
    phone: { value: '+56 ', type: 'Phone', ok: true },
  },
  optIn: false,
  preApprove: {
    accepted: false,
    id: '',
    maxAmount: '0',
    maxInstallmentAmount: 0,
    maxPeriods: 0,
    productType: 'consumer',
    rate: 0,
    currency: {
      abbreviation: 'CLP',
      precision: 0,
    },
  },
  partner: 'Banca.me',
  partnerProducts: ['consumer', 'microLoan'],
  partnerConfigurations: {
    legalName: 'Bancame SpA',
  },
  serialNumber: '',
  experianChallenge: {
    data: {
      RESUMEN: {
        RUT: '',
        USUARIO: '',
        IDCHALLENGE: '',
      },
      PREGUNTAS: [],
      codigoRetorno: '',
    },
    success: false,
    errors: [],
  },
  experianChallengeResponses: {},
  experianChallengeResult: { success: false, errors: [] },
  experianRetries: 0,
  settlementsRequired: 1,
  pensionStatementsRequired: 12,
  identificationId: '',
  invisibleCaptchaToken: '',
  shouldCallBNPL: false,
};

export const nonAutomaticallyRejectedOrigins = ['conquista', 'buyDepa', 'norteVerde', 'goplaceit', 'IRentup'];

export const preLoanRequestStep = {
  ARCHIVED_FAIL: -1,
  NEEDS_CU: 2,
  UPLOAD_DOCUMENTS: 3,
  FILL_FORM: 4,
  LOAN_SIMULATION: 5,
  COMPANY_CONTACT_INFO: 6,
  NEEDS_ICP: 8,
  WAITING_COMPANY_AFFILIATION: 9,
  READY_FOR_LOAN: 9,
  COMPANY_APPROVE: 9,
  ARCHIVED_SUCCESS: 9,
  MANUAL_VERIFICATION: 9,
};

export const steps = [
  'Por favor ingresa tu RUT',
  'Comprobación de datos',
  'Ingreso con ClaveÚnica',
  'Adjuntar documentos',
  'Confirmar datos',
  '',
  <Typography variant="body1" align="center" gutterBottom>
    Datos del
    {' '}
    <b>encargado de Recursos Humanos</b>
  </Typography>,
  'Confirmación condiciones',
  '¡Necesitamos validar tus ingresos a través de tus cotizaciones!',
  <Typography variant="h5">¡Gracias por tu tiempo!</Typography>];

export const getStepContent = (
  step: number,
  currentData: FormSchemaV3,
  refsObject: {
    RejectedFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    RutFormRef:React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    DataFetchingFormRef:React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    UploadDocumentsFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    FillFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    CompanyInfoFormRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    LoanSimulationFormRef:React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    LoanConfirmationRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    NeedsICPRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
    LastStepRef: React.MutableRefObject<FormikProps<FormSchemaV3>|null>,
  },
  handleCurrentData: (data:FormSchemaV3)=> void,
  handleNext: ()=> void,
  handleStep: (st: number)=> void,
  handleEnableLoading: (state:boolean)=> void,
  preLoanRequestId: string,
) => {
  Sentry.setContext(formSchema.product, {
    ...currentData,
    activeStep: step,
  });
  const componentProps = {
    formSchema: currentData,
    handleNext,
    handleStep,
    handleEnableLoading,
    handleCurrentData,
  };
  React.useEffect(() => {
    if (preLoanRequestId.length > 0) {
      handleEnableLoading(true);
      handleStep(1);
    }
  }, [preLoanRequestId]);
  switch (step) {
    case -1:
      return (
        <RejectedForm
          formRef={refsObject.RejectedFormRef}
          {...componentProps}
        />
      );
    case 0:
      return (
        <RutForm
          formRef={refsObject.RutFormRef}
          {...componentProps}
        />
      );
    case 1:
      return (
        <DataFetchingForm
          formRef={refsObject.DataFetchingFormRef}
          {...componentProps}
        />
      );
    case preLoanRequestStep.NEEDS_CU:
      return (
        <ClaveUnicaForm
          formRef={refsObject.DataFetchingFormRef}
          {...componentProps}
        />
      );
    case preLoanRequestStep.UPLOAD_DOCUMENTS:
      return (
        <UploadDocumentsForm
          formRef={refsObject.UploadDocumentsFormRef}
          {...componentProps}
        />
      );
    case preLoanRequestStep.FILL_FORM:
      return (
        <FillForm
          formRef={refsObject.FillFormRef}
          {...componentProps}
        />
      );
    case preLoanRequestStep.LOAN_SIMULATION:
      return (
        <LoanSimulationForm
          formRef={refsObject.LoanSimulationFormRef}
          {...componentProps}
        />
      );
    case preLoanRequestStep.COMPANY_CONTACT_INFO:
      return (
        <CompanyInfoForm
          formRef={refsObject.CompanyInfoFormRef}
          {...componentProps}
        />
      );
    case 7:
      return (
        <LoanConfirmationForm
          formRef={refsObject.LoanConfirmationRef}
          {...componentProps}
        />
      );
    case preLoanRequestStep.NEEDS_ICP:
      return (
        <NeedsICPForm
          formRef={refsObject.NeedsICPRef}
          {...componentProps}
        />
      );
    default:
      return (
        <LastStepForm
          formRef={refsObject.LastStepRef}
          {...componentProps}
        />
      );
  }
};

export const getOriginButtonLabel = (partnerName: string) => {
  if (partnerName === 'Tenpo') {
    return 'Volver a Tenpo';
  }
  if (!partnerName || partnerName.toLowerCase() === 'banca.me') {
    return 'Volver a Banca.me';
  }
  return `Ir a ${partnerName}`;
};
export const getButtonLabel = (step: number, partnerName:string) => {
  if ([steps.length - 1, -1].includes(step)) {
    return getOriginButtonLabel(partnerName);
  }
  return 'Continuar';
};

export function partnerErrorRedirectURL(
  originLabel: string,
  idNumber: string,
  partnerConfiguration: PartnerConfiguration,
) {
  const url = window.location.host;
  switch (originLabel) {
    case 'tenpo':
      return 'https://tenpo.cl/?bancame_error';
    case 'brincus':
      return (url.includes('dev') || url.includes('localhost') || url.includes('beta'))
        ? `https://www.brincus.com/dev/cuenta/bancame_fallo?rut=${idNumber}`
        : `https://home.brincus.com/cuenta/bancame_fallo?rut=${idNumber}`;
    default:
      return (partnerConfiguration.callbacks?.error)
        ? partnerConfiguration.callbacks?.error : undefined;
  }
}

export const archiveUnemployedPLR = async (
  inputFormSchema: FormSchemaV3,
  handleCurrentData: (data: FormSchemaV3) => void,
  handleStep: (step: number) => void,
) => {
  const rejectReason = 'La persona solicitante indica que no posee un contrato de trabajo vigente.';
  await patchPLRStep('ARCHIVED_FAIL', [rejectReason], inputFormSchema.PLRtoken);

  handleCurrentData({
    ...inputFormSchema,
    adminComment: `RECHAZO AUTOMÁTICO: ${rejectReason}`,
  });
  return handleStep(-1);
};
